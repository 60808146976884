import React from "react"

import Layout from "~/components/common/Layout"
import {
  getBackLink,
  getHeaderTitles,
  getNextLink,
  getNextTitle,
} from "~/services/pages"
import withProgress from "~/services/withProgress"
import HeroVideo from "~/components/page/HeroVideo"
import { graphql, useStaticQuery } from "gatsby"
import { InfoboxDetailed } from "~/components/page/Infobox"
import { Box, Flex } from "reflexbox"
import { Text } from "~/components/common/Typography"
import { css } from "@emotion/core"
import Img from "gatsby-image"
import Copyright from "~/components/common/Copyright"
import BoxOverImage from "~/components/common/BoxOverImage"
import BackgroundImage from "gatsby-background-image"
import { mq } from "~/components/common/theme"
import Source from "~/components/common/Source"

const Page = ({ myPage = 4, mySubpage = 3 }) => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(name: { eq: "5.4HERO" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      textBg: file(name: { eq: "5.4-treatedphoto-dimmed" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const column1 = [
    {
      title: "Use one-to-five to get a sense of where you’re starting",
      text: "",
    },
    {
      title: "Start the goal-setting process",
      text:
        "What are the wishes and dreams you and the player have for her behavior?",
    },
    {
      title: "Identify one skill at a time",
      text:
        "It might be tempting to try more, but it’s easiest to go one at a time.",
    },
  ]

  const column2 = [
    {
      title: "Create a plan",
      text:
        "This is where your creative teaching comes into play! What are activities or tasks that can help the player develop?",
    },
    {
      title: "Reinforcement",
      text: "How are you going to help them succeed with the goals?",
    },
    {
      title: "Enlist others",
      text:
        "Who are your allies? Don’t forget other kids can be your helpers in this, along with other adult stakeholders.",
    },
  ]

  const mqMobile = mq({
    display: ["block", "none"],
  })
  const mqNonMobile = mq({
    display: ["none", "block"],
  })

  const TextOverImage = () => (
    <Flex
      flexDirection={["column", "row"]}
      css={css`
        text-shadow: 0px 1px #000000;
      `}
    >
      <Box flexDirection={"column"} mr={[0, 5]} width={["100%", "50%"]}>
        {column1.map((item, index) => (
          <Flex>
            <Box width={"40px"} minWidth={"40px"}>
              <Text
                fontSize={["48px", "48px", "64px"]}
                lineHeight={1}
                fontFamily={"PermanentMarkerRegular"}
                color={"white"}
                mt={5}
              >
                {index + 1}
              </Text>
            </Box>
            <Flex
              flexDirection="column"
              textAlign={"left"}
              ml={5}
              width={"100%"}
            >
              <Box width={"100%"}>
                <Text
                  fontFamily={"PermanentMarkerRegular"}
                  fontSize={["14px", "14px", "18px"]}
                  lineHeight={1.5}
                  color={"white"}
                  my={0}
                >
                  {item.title}
                </Text>
              </Box>
              <Box width={"100%"}>
                <Text
                  fontSize={["12px"]}
                  lineHeight={1.5}
                  color={"white"}
                  mt={3}
                >
                  {item.text}
                </Text>
              </Box>
            </Flex>
          </Flex>
        ))}
      </Box>
      <Box flexDirection={"column"} ml={[0, 5]} width={["100%", "50%"]}>
        {column2.map((item, index) => (
          <Flex>
            <Box width={"40px"} minWidth={"40px"}>
              <Text
                fontSize={["48px", "48px", "64px"]}
                lineHeight={1}
                fontFamily={"PermanentMarkerRegular"}
                color={"white"}
                mt={5}
              >
                {index + 4}
              </Text>
            </Box>
            <Flex
              flexDirection="column"
              textAlign={"left"}
              ml={5}
              width={"100%"}
            >
              <Box width={"100%"}>
                <Text
                  fontFamily={"PermanentMarkerRegular"}
                  fontSize={["14px", "14px", "18px"]}
                  lineHeight={1.5}
                  color={"white"}
                  my={0}
                >
                  {item.title}
                </Text>
              </Box>
              <Box width={"100%"}>
                <Text
                  fontSize={["12px"]}
                  lineHeight={1.5}
                  color={"white"}
                  mt={3}
                >
                  {item.text}
                </Text>
              </Box>
            </Flex>
          </Flex>
        ))}
      </Box>
    </Flex>
  )

  const { hero, textBg } = data
  return (
    <Layout
      myPage={myPage}
      mySubpage={mySubpage}
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getBackLink(myPage, mySubpage)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
        nextLink: `${getNextLink(myPage, mySubpage)}`,
      }}
    >
      <HeroVideo
        headingLarge="There’s no one way to manage behavior, and there’s no “magic” way to teach every kid."
        image={hero}
        bg={"white"}
      />

      <InfoboxDetailed
        headerMaxWidth={"950px"}
        maxWidth={"100%"}
        contentMaxWidth={"tablet"}
        headerText={"Skills must be taught intentionally."}
      >
        <Flex flexDirection={"column"}>
          <Box mx={"auto"}>
            <Text>
              Just because you are present doesn’t mean that the athlete is
              learning skills. Learning to teach skills and effectively assess
              whether or not girls have mastered the skills is an important part
              of coaching.<Source>43</Source>
            </Text>
            <Text>
              As a coach, you have a responsibility to work with players
              individually, away from the team and sports experience, to build a
              plan for them to use the sports experience to rewrite their
              stories.
            </Text>
          </Box>
        </Flex>
      </InfoboxDetailed>

      <InfoboxDetailed
        headerText={"The plan format"}
        maxWidth={"100%"}
        bg={"seafoam"}
        headerBackground={"transparent"}
        contentMaxWidth={"desktop"}
      >
        <Box bg={"white-panel"} mt="5" px={[5, 5, 6]} py={[6, 6]}>
          <Flex flexDirection={"column"} mt={5} maxWidth={"tablet"} mx={"auto"}>
            <Text mt={0}>
              This behavior skill-building format is divided into six steps,
              each with a set of questions for you as the coach to ask yourself
              and to work with the player.
            </Text>
            <Text
              uppercase
              fontSize={["18px", "24px"]}
              lineHeight={["27px", "27px"]}
              fontFamily={"AdineuePROBlackWeb"}
              color={"grey-text"}
              mt={0}
            >
              Individual Success Plan
            </Text>
            <Box
              width={"100%"}
              mb={[5, 0]}
              mt={4}
              css={css`
                position: relative;
                height: fit-content;
              `}
            >
              <BackgroundImage
                Tag="div"
                loading={"eager"}
                fluid={textBg.childImageSharp.fluid}
                backgroundColor={`#333333`}
                css={p => [
                  css`
                    /* Sizing */
                    width: 100%;
                    height: fit-content;

                    /* Background styles */
                    background-size: cover;
                    background-position: 60% center;
                    background-repeat: no-repeat;
                    background-attachment: scroll;
                    //overflow: hidden;
                  `,
                  mqMobile,
                ]}
              >
                <Box width={"90%"} mx={"auto"} py={7}>
                  <TextOverImage />
                </Box>
              </BackgroundImage>

              <BoxOverImage width={90} css={mqNonMobile}>
                <TextOverImage />
              </BoxOverImage>
              <Img
                css={mqNonMobile}
                fluid={textBg.childImageSharp.fluid}
                margin={"0 auto"}
                loading={"eager"}
              ></Img>
            </Box>

            <Text>
              This process can help you further support players through their
              journeys. Think about what the benefits of this approach are. What
              are some challenges you might face on the way?
            </Text>
            <Text>
              This method requires a lot of work and commitment. But remember
              why you are coaching: to help girls grow. That’s the most
              important rewiring you can do, because this girl may have been
              challenged in other programs before coming to you. She needs you.
              We’re not just talking about a group plan now, we’re taking it
              down to the individual level because we have to bring the program
              to the player. That’s where she finds success.
            </Text>
          </Flex>
        </Box>
      </InfoboxDetailed>

      <Copyright bg={"seafoam"} />
    </Layout>
  )
}

export default withProgress(Page, 4, 3)
